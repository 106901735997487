import React, { useEffect, useState } from "react";
import {IonContent, IonHeader, IonPage, IonToolbar, IonIcon, IonButton, IonBackButton, IonBadge, useIonViewWillEnter,} from '@ionic/react';
import {link, notificationsOutline, searchOutline, arrowBackOutline} from 'ionicons/icons';
import './Tab1.scss';
import CustomSlider from "../../components/CustomSlider";
import { useHistory, Link } from "react-router-dom";
import { Plugins, Capacitor } from "@capacitor/core";
//axios and base url
import axios from "axios";
import { BASE_URL,mode } from "../../BaseUrll";
import Cookies from "universal-cookie";
import instance from "../../mocks";

const cookies = new Cookies();


const Tab1: React.FC = () => {
    let history = useHistory();

    let [unreadNotification, setUnreadNotification] = useState(false);

    useEffect(() =>{
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
                if (window.location.pathname === "/") {
                // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Are you sure");
                if (ans) {
                    Plugins.App.exitApp();
                } 
                } else if (window.location.pathname === "/main/tab1") {
                    // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Are you sure");
                if (ans) {
                    Plugins.App.exitApp();
                } 
                } 
            });
        }
    }, []);

    useIonViewWillEnter(()=>{
        if(localStorage.getItem('loves_burger_notifications')){
            let notificationss:any = localStorage.getItem('loves_burger_notifications');
            notificationss = JSON.parse(notificationss);
            
            notificationss.map((item:any)=>{
                if(!item.is_read){
                    setUnreadNotification(true);
                }else{
                    setUnreadNotification(false);
                }
            });
        }

        const url = BASE_URL + "/app/menu";
       if(mode === "demo"){
        instance.get('/app/menu')
        .then(response =>{
            console.log(response)
        })
       }
       else{

           axios
           .get(url, {
               headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
           })
           .then((res) => {
               if(res.data.status){
                   let items_search_array:any = [];
                   res.data.data.map((category:any, index:any)=>{
                       category.items.map((item:any, key:any)=>{
                           items_search_array.push(item);
                       });
                   });
                   
                   localStorage.setItem('loves_burger_items_search_array', JSON.stringify(items_search_array));
               }
           })
           .catch((error) => {
               console.log(error);
           });
       }

        // const req_url = BASE_URL + "/app/notifications";
        // axios
        // .get(req_url, {
        //     headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
        // })
        // .then((res) => {
        //     localStorage.setItem('loves_burger_notifications', JSON.stringify(res.data.data));
        //     res.data.data.map((item:any)=>{
        //         if(!item.is_read){
        //             setUnreadNotification(true);
        //         }
        //     });
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    });

    return (
        <IonPage className="home-page">
            {/*Header Start*/}
            <IonHeader className="home-header ion-no-border">
                <IonToolbar>
                    {/* <IonBackButton text="" icon={arrowBackOutline} /> */}
                    <div slot="end">
                    {/* <IonBadge color="danger">66</IonBadge> */}
                    {/* {unreadNotification? <IonBadge color="danger"> </IonBadge>: ''} */}
                    <IonIcon className={unreadNotification? 'badge':''} slot="end" icon={notificationsOutline} onClick={() =>{
                        history.push("/notifications");
                    }}/>
                    </div>
                    
                    

                    <IonIcon slot="end" icon={searchOutline} onClick={() =>{
                        history.push("/search");
                    }}/>
                    {/* <IonIcon icon={notificationsOutline} slot="end"></IonIcon> */}
                    

                    {/* <IonIcon icon={searchOutline} slot="end"></IonIcon> */}
                </IonToolbar>
            </IonHeader>
            {/*Header End*/}

            <IonContent>
                {/*Home Banner Start*/}
                <div className="home-banner ion-justify-content-center ion-align-items-center">
                    <div className="banner-content">
                        <h6 className="ion-text-uppercase">order before everyone</h6>
                        <h1>Loves Burger</h1>
                        <h1>Get Your Desire</h1>
                    </div>

                    <Link to="/menu"><IonButton expand="block" shape="round">Order Now!</IonButton></Link>
                </div>
                {/*Home Banner End*/}

                {/*Product Slider Component Start*/}
                <CustomSlider/>
                {/*Product Slider Component End*/}
            </IonContent>
        </IonPage>
    );
};

export default Tab1;
