import React, { useEffect, useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonButton,
    IonTitle,
    IonCard,
    IonImg,
    IonBackButton,
    IonButtons,
    IonBadge, IonText, IonSlide,
    IonRefresher, IonRefresherContent,
    useIonViewWillEnter,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import {arrowBackOutline, notificationsOutline, searchOutline, chevronDownCircleOutline} from 'ionicons/icons';
import './Menu.scss';
import MenuSlider from "../../components/MenuSlider";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL ,mode} from "../../BaseUrll";
import instance from "../../mocks";

const cookies = new Cookies();

const Menu: React.FC = (props) => {
    let history = useHistory();

    const [itemsWithCategory, setItemsWithCategory] = useState([]);
    const [listing, setListing] = useState<any>([]);
    const [count, setCount] = useState<any>('');

    useEffect(() =>{
        const url = BASE_URL + "/app/menu";
        if(mode === "demo"){
     
            instance.get("/app/menu")
            .then(response => {
                if(response.data.status){
                    console.log(response.data.data.items)
                    setItemsWithCategory(response.data.data.items);
                    setListing(response.data.data.items);
                    let items_search_array:any = [];
                    response.data.data.items.map((category:any, index:any)=>{
                        category.category.map((item:any, key:any)=>{
                            items_search_array.push(item);
                        });
                    });
                    
                    localStorage.setItem('loves_burger_items_search_array', JSON.stringify(items_search_array));
            }})
        }
        else{

            axios
            .get(url, {
                headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
            })
            .then((res) => {
                if(res.data.status){
                    setItemsWithCategory(res.data.data);
                    setListing(res.data.data[0]);
                    let items_search_array:any = [];
                    res.data.data.map((category:any, index:any)=>{
                        category.items.map((item:any, key:any)=>{
                            items_search_array.push(item);
                        });
                    });
                    
                    localStorage.setItem('loves_burger_items_search_array', JSON.stringify(items_search_array));
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },[]);

    useIonViewWillEnter(() =>{
        // setCount(Math.random());
        // const url = BASE_URL + "/app/menu";
        // axios
        // .get(url, {
        //     headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
        // })
        // .then((res) => {
        //     console.log(res.data);
        //     if(res.data.status){
        //         setItemsWithCategory(res.data.data);
        //         setListing(res.data.data[0]);
        //     }
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    });

    const sendDataToParent = (index:any) => { // the callback. Use a better name
        setListing(itemsWithCategory[index]);
        localStorage.setItem('category_index', index);
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        const url = BASE_URL + "/app/menu";
        
        if(mode === "demo"){
            console.log("demo")
            instance.get("/app/menu")
            .then(response => {
                setItemsWithCategory(response.data.data.items);
                setListing(response.data.data.items);
                let items_search_array:any = [];
                response.data.data.map((category:any, index:any)=>{
                    category.items.map((item:any, key:any)=>{
                        items_search_array.push(item);
                    });
                });
                
                localStorage.setItem('loves_burger_items_search_array', JSON.stringify(items_search_array));
                event.detail.complete();
                
            })
        }
        else{

            axios
            .get(url, {
                headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
            })
            .then((res) => {
                if(res.data.status){
                    setItemsWithCategory(res.data.data);
                    setListing(res.data.data[0]);
                    let items_search_array:any = [];
                    res.data.data.map((category:any, index:any)=>{
                        category.items.map((item:any, key:any)=>{
                            items_search_array.push(item);
                        });
                    });
                    
                    localStorage.setItem('loves_burger_items_search_array', JSON.stringify(items_search_array));
                    event.detail.complete();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <IonPage>
            {/*Header Start*/}
            <IonHeader className="menu-header header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        {/* <Link to="/main/tab1">
                            <IonIcon slot="start" icon={arrowBackOutline} />
                        </Link> */}
                        {/* <IonIcon slot="start" icon={arrowBackOutline} /> */}
                        <IonBackButton text="" icon={arrowBackOutline} />
                        
                    </IonButtons>
                    {/* <IonIcon slot="start" icon={arrowBackOutline} className="ion-margin-start"/> */}
                    
                    <IonTitle>Menu</IonTitle>
                    <IonIcon slot="end" icon={notificationsOutline} onClick={() =>{
                        history.push("/notifications");
                    }}/>

                    <IonIcon slot="end" icon={searchOutline} onClick={() =>{
                        history.push("/search");
                    }}/>
                </IonToolbar>
            </IonHeader>
            {/*Header End*/}

            <IonContent className="menu-container">
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {/*Menu Button Slider Component Start*/}
                <MenuSlider itemsWithCategory={itemsWithCategory} sendDataToParent={sendDataToParent}/>
                {/*Menu Button Component End*/}

                {/*Menu Container Start*/}
                <div className="menu-items-container ion-justify-content-center ion-align-items-center">
                    <h4 className="ion-text-uppercase ion-text-center">{listing.name}</h4>

                    <div className="menu-item">
                        {listing.items && listing.items.map((item:any, key:any) =>{
                            return(
                                <IonCard key={key} onClick={() =>{
                                    localStorage.setItem('selected_item', JSON.stringify(item));
                                    localStorage.setItem('item_index', key);
                                    history.push('/add-cart/'+item.id);
                                }}>
                                    <IonImg src={item.image_url}/>
                                    <IonBadge>$ {item.price}</IonBadge>
                                    <IonText className="ion-text-capitalize ion-text-left">{item.name}</IonText>
                                </IonCard>
                            )
                        })}
                    </div>
                </div>
                {/*Menu Container End*/}
            </IonContent>
        </IonPage>
    );
    
};

export default Menu;
