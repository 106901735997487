import {
    IonContent,
    IonAvatar,
    IonPage,
    IonImg,
    IonLabel,
    IonItem,
    IonList,
    IonIcon
} from '@ionic/react';
import {chevronForwardOutline} from "ionicons/icons";
import './UserTab.scss';
import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const UserTab: React.FC = (props) => {
    let history = useHistory();
    const [user, setUser] = useState<any>({});

    useEffect(()=>{
        let user:any = localStorage.getItem('loves_burger_user');
        user = JSON.parse(user);
        setUser(user);
    },[]);

    return (
        <IonPage>
            <IonContent fullscreen className="user-profile">
                <div className="user-header-bg"></div>
                <div className="user-header ion-justify-content-center">
                    <IonAvatar>
                        {user.image? <img src={user.image}/>: <img src={require("../../assets/images/user-avatar.jpg").default}/>}
                    </IonAvatar>
                    <IonLabel><h1 className="ion-text-center">{user.name}</h1></IonLabel>
                </div>
                <IonList className="user-setting-list custom-list">
                    <IonItem button detail onClick={() =>{history.push('/account-settings')}}>
                        <IonLabel>
                            <h2 className="ion-text-capitalize">Account Settings</h2>
                        </IonLabel>
                    </IonItem>
                    <IonItem button detail onClick={() =>{history.push('/transactions')}}>
                        <IonLabel>
                            <h2 className="ion-text-capitalize">Order History</h2>
                        </IonLabel>
                    </IonItem>
                    <IonItem button detail onClick={() =>{history.push('/contact-us')}}>
                        <IonLabel>
                            <h2 className="ion-text-capitalize">Contact Us</h2>
                        </IonLabel>
                    </IonItem>
                    <IonItem button detail onClick={() =>{history.push('/about-us')}}>
                        <IonLabel>
                            <h2 className="ion-text-capitalize">About App</h2>
                        </IonLabel>
                    </IonItem>
                    <IonItem button detail routerLink="/login" onClick={() =>{
                            let date = new Date();
                            date.setFullYear(date.getFullYear() + 1);
                            cookies.remove('access_token_loves_burger', {
                                path: "/",
                            });
                            localStorage.setItem('loves_burger_cart_final', JSON.stringify([]));
                            localStorage.setItem('loves_burger_cart', JSON.stringify([]));
                            localStorage.removeItem('loves_burger_notifications');
                            history.replace('/login');
                        }
                    }>
                        <IonLabel>
                            <h2 className="ion-text-capitalize">Logout</h2>
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default UserTab;
