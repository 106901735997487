import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonAvatar,
    IonPage,
    IonSearchbar,
    IonButtons,
    IonList,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonItem,
    IonLabel,
    IonSpinner,
    useIonViewWillEnter,
    IonImg, IonRadio, IonRadioGroup, IonListHeader, IonTabButton, IonButton, IonText, IonBackButton
} from '@ionic/react';
import {arrowBackOutline} from 'ionicons/icons';
import './Transactions.scss';
import burger from '../assets/images/Chicken-Burgers.jpg';
//axios and base url
import axios from "axios";
import { BASE_URL ,mode} from "../../BaseUrll";
import instance from "../../mocks";

import Cookies from "universal-cookie";
import { useHistory, Link } from "react-router-dom";

const cookies = new Cookies();

const Transactions: React.FC = () => {
    const [orders, setOrders] = useState<any>([]);
    let [doSpin, setDoSpin] = useState(true);

    let history = useHistory();

    useIonViewWillEnter(()=>{
        const url = BASE_URL + "/app/order-history";
        if(mode == "demo"){
            instance.get("/app/order-history")
            .then((response)=>{
                if(response.data.data.length <= 0){
                    setDoSpin(false);
                }
                setOrders(response.data.data);
            })
        }
        else{
            axios
            .get(url, {
                headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
            })
            .then((res) => {
                if(res.data.data.length <= 0){
                    setDoSpin(false);
                }
                setOrders(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }

    });

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        {/* <IonBackButton text="" icon={arrowBackOutline} /> */}
                        <IonIcon slot="start" icon={arrowBackOutline} onClick={() =>{
                            history.push('/main/user');
                        }}/>
                    </IonButtons>
                    <IonTitle>Transactions</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="transactions-container ion-padding">

                <IonList className="transactions-list custom-list">
                    {orders.length > 0? 
                        orders.map((order:any, index:any) => {
                            var d = new Date(order.created_at),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();
    
                            if (month.length < 2) 
                                month = '0' + month;
                            if (day.length < 2) 
                                day = '0' + day;
                            let order_date = year+'-'+month+'-'+day;
    
                            let food_items:string = '';
                            order.order_items.map((item:any, key:any)=>{
                                if((order.order_items.length -1) == key){
                                    food_items += item.food_item;
                                }else{
                                    food_items += item.food_item+', ';
                                }
                            });
    
                            return(
                                <IonItem key={index}>
    
                                <IonLabel className="ion-text-left">
                                    <IonText>{order_date}</IonText>
                                    <h2 className="ion-text-capitalize">{food_items}</h2>
                                </IonLabel>
    
                                <IonText slot="end" className="order-item-price">${order.total_payable}</IonText>
                            </IonItem>
                            )
                        })
                        :
                        doSpin? <IonSpinner name="crescent" />:<p>No Order History</p>
                    }
                </IonList>
            </IonContent>
        </IonPage>
    )
};

export default Transactions;
