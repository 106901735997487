import React, {useRef, useState, useEffect} from "react";
import {CreateAnimation, Animation} from '@ionic/react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonButtons,
    IonImg,
    IonToolbar,
    IonIcon,
    IonToast,
    IonTitle, IonCol, IonButton, IonText, IonRow, IonGrid, IonLabel, IonBackButton,
    useIonViewWillEnter,
} from '@ionic/react';
import {arrowBackOutline, star, create, heart, heartOutline, addOutline, removeOutline} from 'ionicons/icons';
import './CartConfirm.scss';
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrll";

const cookies = new Cookies();

const CartConfirm: React.FC = () => {
    const [imageBg, setImageBg] = useState('');
    const [item, setItem] = useState<any>([]);
    const [itemPrice, setItemPrice] = useState(0);
    const [basePrice, setBasePrice] = useState(0);
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    let history = useHistory();

    useEffect(()=>{
        let cart:any = localStorage.getItem('loves_burger_cart');
        cart = JSON.parse(cart);

        let selected:any = localStorage.getItem('selected_item');
        selected = JSON.parse(selected);
        
        setItemPrice(parseInt(selected.total_price));
        setBasePrice(parseInt(selected.total_price));
        setItem(selected);
        setImageBg(selected.image_url);

        let bg:HTMLElement = document.getElementsByClassName('cart-confirm-container')[0] as HTMLElement;
        bg.style.background = 'url('+selected.image_url+') top center no-repeat';
    },[]);

    useIonViewWillEnter(() =>{
        
    });

    const addToFavourites = () =>{
        if(item.is_favourite){
            let iteem:any = item;
            iteem.is_favourite = false;

            setItem(iteem);
            
        }else{
            let iteem:any = item;
            iteem.is_favourite = true;

            setItem(iteem);
        }
        
        const url = BASE_URL + "/app/add-to-favourites/"+item.id;
        axios
        .post(url, {}, {
            headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
        })
        .then((res) => {
            if(res.data.status){
                setMessage(res.data.message);
                setShowToast(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleItemQuantity = (type:string) =>{
        let cart:any = localStorage.getItem('loves_burger_cart');
        cart = JSON.parse(cart);

        if(type == 'plus'){
            let iteeme = item;
            let qty:any = iteeme.food_item_quantity + 1;
            iteeme.food_item_quantity = qty;
            setItem(iteeme);
            
            let price:number = itemPrice;
            cart.map((iteem:any, index:any)=>{
                if(iteem.food_item_id == item.id){
                    iteem.food_item_quantity = iteem.food_item_quantity + 1;
                    price = price + basePrice;
                    iteem.total_price = price;
                }
            });
            localStorage.setItem('loves_burger_cart', JSON.stringify(cart));
            setItemPrice(price);
        }else if(type == 'minus' && item.food_item_quantity > 1){
            let iteem = item;
            iteem.food_item_quantity -= 1;
            let price:number = itemPrice;
            cart.map((iteem:any, index:any)=>{
                if(iteem.food_item_id == item.id){
                    iteem.food_item_quantity -= 1;
                    price -= basePrice;
                    iteem.total_price = price;
                }
            });
            localStorage.setItem('loves_burger_cart', JSON.stringify(cart));
            setItem(iteem);
            setItemPrice(price);
        }
    }

    const handleCart = () =>{
        let cart:any = localStorage.getItem('loves_burger_cart');
        cart = JSON.parse(cart);
        
        cart.map((iteem:any, index:any)=>{
            if(iteem.food_item_id == item.id){
                iteem.total_price = itemPrice;
            }
        });
        localStorage.setItem('loves_burger_cart', JSON.stringify(cart));
        history.replace('/main/tab1');
    }

        return (
            <IonPage>
                <IonHeader className="header-center">
                    <IonToolbar color="primary">
                        <IonButtons slot="start" className="ion-margin-start">
                            <IonBackButton text="" icon={arrowBackOutline} />
                        </IonButtons>
                        <IonButtons slot="end" className="ion-margin-end">
                            {item.is_favourite?
                                (
                                    <IonIcon slot="end" id='one' icon={heart} onClick={() =>{
                                        addToFavourites();
                                    }}/>
                                )
                            :
                                (
                                    <IonIcon slot="end" id='two' icon={heartOutline} onClick={() =>{
                                        addToFavourites();
                                    }}/>
                                )
                            }
                            {/* <IonIcon slot="end" icon={heartOutline}/> */}
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div className="cart-confirm-container" style={{background: 'url('+{imageBg}+') top center no-repeat !important', height: '100%'}}>
                        <CreateAnimation
                            duration={600}
                            fromTo={[
                                {
                                    property: 'transform',
                                    fromValue: 'translateY(-100%)',
                                    toValue: 'translateY(0px)'
                                }
                            ]}
                            play={true}
                        >
                            <div className="cart-confirm">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonLabel>
                                                <h1 className="ion-text-capitalize">{item.name}</h1>
                                            </IonLabel>
                                        </IonCol>

                                        <IonCol size="5">
                                            <IonText>${itemPrice}</IonText>

                                            <div className="item-counter">
                                                <IonButton onClick={()=>{
                                                    handleItemQuantity('minus');
                                                }}>
                                                    <IonIcon icon={removeOutline}></IonIcon>
                                                </IonButton>

                                                <IonText className="ion-align-items-center">{item.food_item_quantity}</IonText>

                                                <IonButton onClick={()=>{
                                                    handleItemQuantity('plus');
                                                }}>
                                                    <IonIcon icon={addOutline}></IonIcon>
                                                </IonButton>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </CreateAnimation>
                        <IonButton className="btn-default ion-text-uppercase" expand="block" onClick={() =>{
                            handleCart();
                        }}>Ok</IonButton>
                    </div>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={message}
                        duration={700}
                        cssClass="loves-burger-toast"
                    />
                </IonContent>
            </IonPage>
        );
}

export default CartConfirm;
