import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonAvatar,
  IonPage,
  IonSearchbar,
  IonButtons,
  IonList,
  IonToolbar,
  IonIcon,
  IonTitle,
  IonItem,
  IonLabel,
  IonImg,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonTabButton,
  IonButton,
  IonText,
  IonItemDivider,
  IonItemGroup,
  IonDatetime,
  IonToast,
  IonToggle,
  IonAlert,
  IonBackButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import "./AccountSettings.scss";
import burger from "../assets/images/Chicken-Burgers.jpg";
//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../../BaseUrll";
import instance from "../../mocks";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const AccountSettings: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [user, setUser] = useState<any>({});
  const [input, setInput] = useState<any>([]);
  const [alertHeader, setAlertHeader] = useState<any>("");
  const [type, setType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [switched, setSwitched] = useState(false);

  useEffect(() => {
    let user: any = localStorage.getItem("loves_burger_user");
    user = JSON.parse(user);
    setUser(user);

    const url = BASE_URL + "/app/me";
    if (mode == "demo") {
      instance.get("/app/me").then((response) => {
        let d: any = new Date(response.data.data.dob);
        d.setHours(1);
        d.setMinutes(1);
        d.setSeconds(1);
        d.setMilliseconds(1);
        d = d.toISOString();
        let userData = {
          id: response.data.data.id,
          name: response.data.data.name,
          email: response.data.data.email,
          image: response.data.data.image,
          phn_no: response.data.data.phn_no,
          address: response.data.data.address,
          dob: d,
        };
        setUser(userData);
      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
          },
        })
        .then((res) => {
          let d: any = new Date(res.data.data.customer.dob);
          d.setHours(1);
          d.setMinutes(1);
          d.setSeconds(1);
          d.setMilliseconds(1);
          d = d.toISOString();
          let userData = {
            id: res.data.data.id,
            name: res.data.data.name,
            email: res.data.data.email,
            image: res.data.data.image,
            phn_no: res.data.data.phn_no,
            address: res.data.data.customer.address,
            dob: d,
          };
          setUser(userData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleInput = (type: string) => {
    if (type == "name") {
      let arr: any = [
        {
          name: "name",
          type: "text",
          value: user.name,
        },
      ];
      setInput(arr);
      setAlertHeader("Edit Name");
      setShowAlert1(true);
      setType(type);
    } else if (type == "email") {
      let arr: any = [
        {
          name: "email",
          type: "text",
          value: user.email,
        },
      ];
      setInput(arr);
      setAlertHeader("Edit Email");
      setShowAlert1(true);
      setType(type);
    } else if (type == "phone") {
      let arr: any = [
        {
          name: "phone",
          type: "text",
          value: user.phn_no,
        },
      ];
      setInput(arr);
      setAlertHeader("Edit Phone Number");
      setShowAlert1(true);
      setType(type);
    } else if (type == "address") {
      let arr: any = [
        {
          name: "address",
          type: "text",
          placeholder: "Enter address",
          value: user.address,
        },
      ];
      setInput(arr);
      setAlertHeader("Edit Address");
      setShowAlert1(true);
      setType(type);
    }
  };

  useIonViewWillEnter(() => {
    setSwitched(false);
  });

  const handleUserDetailUpdate = (val: any) => {
    if (switched) {
      var d = new Date(val),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dobb = year + "-" + month + "-" + day;

      setUser({
        ...user,
        dob: dobb,
      });

      let data = {
        name: user.name,
        email: user.email,
        phn_no: user.phn_no,
        address: user.address,
        dob: dobb,
      };
      updateCall(data);
    } else {
      setSwitched(true);
    }
  };

  const handleInputUpdate = async (alertData: any) => {
    var d = new Date(user.dob),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let dobb = year + "-" + month + "-" + day;

    let data = {
      name: user.name,
      email: user.email,
      phn_no: user.phn_no,
      address: user.address,
      dob: dobb,
    };
    if (type == "name") {
      await setUser({
        ...user,
        name: alertData.name,
      });
      data.name = alertData.name;
    } else if (type == "email") {
      await setUser({
        ...user,
        email: alertData.email,
      });
      data.email = alertData.email;
    } else if (type == "phone") {
      if (
        !/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
          alertData.phone
        )
      ) {
        setMessage("Invalid phone number");
        setShowToast(true);
        return;
      } else {
        await setUser({
          ...user,
          phn_no: alertData.phone,
        });
        data.phn_no = alertData.phone;
      }
    } else if (type == "address") {
      await setUser({
        ...user,
        address: alertData.address,
      });
      data.address = alertData.address;
    }

    updateCall(data);
  };

  const updateCall = (data: any) => {
    const url = BASE_URL + "/app/update-me";
    if (mode == "demo") {
      let user = {
        name: data.name,
        address: data.address,
        dob: data.dob,
        phn_no: data.phn_no,
      };
      instance.post("/app/update-me").then((response) => {
        localStorage.setItem("loves_burger_user", JSON.stringify(user));
        setMessage(response.data.message);
        setShowToast(true);
      });
    } else {
      axios
        .post(
          url,
          {
            name: data.name,
            address: data.address,
            dob: data.dob,
            phn_no: data.phn_no,
          },
          {
            headers: {
              Authorization: `Bearer ${cookies.get(
                "access_token_loves_burger"
              )}`,
            },
          }
        )
        .then((res) => {
          localStorage.setItem("loves_burger_user", JSON.stringify(user));
          setMessage(res.data.message);
          setShowToast(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <IonPage>
      <IonHeader className="header-center">
        <IonToolbar color="primary">
          <IonButtons slot="start" className="ion-margin-start">
            <IonBackButton text="" icon={arrowBackOutline} />
          </IonButtons>
          <IonTitle>Account Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="input-alert"
        header={alertHeader}
        inputs={input}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Ok",
            handler: (alertData) => {
              handleInputUpdate(alertData);
            },
          },
        ]}
      />

      <IonContent fullscreen className="account-container">
        <IonItemGroup className="custom-list">
          <IonItemDivider>
            <IonLabel>
              <h1 className="ion-text-capitalize">Accounts</h1>
            </IonLabel>
          </IonItemDivider>

          <IonItem
            button
            className="ion-padding-start"
            onClick={() => handleInput("name")}
          >
            <IonLabel slot="start" id="name">
              <h2 className="ion-text-capitalize">Name</h2>
            </IonLabel>
            <IonLabel slot="" id="name">
              <p className="ion-text-right">{user.name}</p>
            </IonLabel>
          </IonItem>

          <IonItem
            button
            className="ion-padding-start"
            disabled={true}
            onClick={() => handleInput("email")}
          >
            <IonLabel slot="start">
              <h2 className="ion-text-capitalize">Email</h2>
            </IonLabel>
            <IonLabel>
              <p className="ion-text-right">{user.email}</p>
            </IonLabel>
          </IonItem>

          <IonItem
            button
            className="ion-padding-start"
            onClick={() => handleInput("phone")}
          >
            <IonLabel>
              <h2 className="ion-text-capitalize">Telephone</h2>
            </IonLabel>
            <IonLabel>
              <p className="ion-text-right">{user.phn_no}</p>
            </IonLabel>
          </IonItem>

          <IonItem
            button
            className="ion-padding-start"
            onClick={() => handleInput("address")}
          >
            <IonLabel slot="start">
              <h2 className="ion-text-capitalize">Address</h2>
            </IonLabel>

            <IonLabel>
              {user.address ? (
                <p className="ion-text-right">{user.address}</p>
              ) : (
                <p className="ion-text-right">Enter your address</p>
              )}
            </IonLabel>
          </IonItem>

          <IonItem button className="ion-padding-start">
            <IonLabel slot="start">
              <h2 className="ion-text-capitalize">Birthday</h2>
            </IonLabel>

            <IonDatetime
              className="ion-text-right"
              placeholder="DD MMMM YYYY"
              displayFormat="DD MMMM YYYY"
              value={user.dob ? user.dob : ""}
              onIonChange={(e) => {
                handleUserDetailUpdate(e.detail.value);
              }}
            ></IonDatetime>
          </IonItem>

          {/* <IonItemDivider>
                        <IonLabel><h1 className="ion-text-capitalize">Accounts</h1></IonLabel>
                    </IonItemDivider>

                    <IonItem className="ion-padding-start">
                        <IonLabel><h2>Notifications</h2></IonLabel>
                        <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                    </IonItem>

                    <IonItem className="ion-padding-start" button detail>
                        <IonLabel>
                           <h2>Credit Card</h2>
                        </IonLabel>
                    </IonItem> */}
        </IonItemGroup>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={700}
          cssClass="loves-burger-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;
