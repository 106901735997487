import React, {useState} from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonButtons,
    IonImg,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonBackButton,
    IonItem,
    IonInput,
    IonFabButton,
    IonToast
} from '@ionic/react';
import './ForgetPassword.scss';
import {arrowBackOutline, atOutline, lockOpenOutline, informationCircleOutline, arrowForwardOutline} from 'ionicons/icons';

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrll";


const ForgetPassword: React.FC = (props) => {
    let [email, setEmail] = useState('');
    let [message, setMessage] = useState('');
    let [remainingTime, setRemainingTime] = useState<any>('');
    let [showToast, setShowToast] = useState(false);
    let [waiting, setWaiting] = useState(false);

    const handleEmail = (e:any) =>{
        setEmail(e);
    }

    //submit credentials to server to send token to email
    const handleSubmit = (e:any) => {
        e.preventDefault();

        if(email == ''){
            setMessage('Email must be provided');
            setShowToast(true);
            return;
        }else if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
            setMessage('Invalid Email');
            setShowToast(true);
            return;
        }

        setRemainingTime('');
        setWaiting(true);

        var duration:any = 60 * 2;
        var timer:any = duration, minutes, seconds;
        
        var aa = setInterval(function () {
            minutes = timer / 60;
            minutes = minutes.toString();
            minutes = minutes.split('.');
            minutes = minutes[0];
            minutes = parseInt(minutes);
            seconds = timer % 60;

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            setRemainingTime('Resend email in '+minutes+':'+seconds+' seconds');

            if (--timer < 0) {
                //timer = duration;
                clearInterval(aa);
                setWaiting(false);
            }
        }, 1000);

        const url = BASE_URL + "/auth/resetPassword";
        return axios
            .post(url, {email: email})
            .then((res) => {
            if (res.data === "banned") {
                setMessage('Sorry, you are banned!');
                setShowToast(true);

                clearInterval(aa);
                setRemainingTime('');
                setWaiting(false);
            }
            if (res.data === "noUser") {
                setMessage('Sorry, no user with this email!');
                setShowToast(true);

                clearInterval(aa);
                setRemainingTime('');
                setWaiting(false);
            }
            if (res.data === "ok") {
                setMessage('A link has been sent to your email, Please check your email to reset your password');
                setShowToast(true);

                clearInterval(aa);
                setRemainingTime('');
                setWaiting(false);
            }
            if (res.data === "smtp") {
                setMessage('Email configuration needs to be setup by admin to reset password');
                setShowToast(true);

                clearInterval(aa);
                setRemainingTime('');
                setWaiting(false);
            }
            })
            .catch((error) => {
                setMessage('Please try again!');
                setShowToast(true);

                clearInterval(aa);
                setRemainingTime('');
                setWaiting(false);
            });
    };

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        {/* <IonIcon slot="start" icon={arrowBackOutline}/> */}
                        <IonBackButton text="" icon={arrowBackOutline} />
                    </IonButtons>
                    <IonTitle>Forget Password</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding">
                <div className="about-us">
                    <div className="input-group">
                        <IonIcon className="ico" icon={atOutline}></IonIcon>
                        <IonItem className="item-input">
                            <IonInput placeholder="Email Address" onIonChange={(e) => {
                                handleEmail(e.detail.value);
                            }}/>
                        </IonItem>
                    </div>
                    <div className="input-group">
                        {waiting? remainingTime:''}
                    </div>
                    <div className="bottom-btn">
                        {waiting? 
                            ''
                            :
                            <IonFabButton color="primary" onClick={(e) => {handleSubmit(e);}}>
                                <IonIcon icon={arrowForwardOutline}></IonIcon>
                            </IonFabButton>
                        }
                    </div>
                </div>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={message}
                    duration={2200}
                    cssClass="loves-burger-toast"
                />
            </IonContent>
        </IonPage>
    );

}

export default ForgetPassword;
