import {
  IonContent,
  IonItem,
  IonIcon,
  IonImg,
  IonButton,
  IonLabel,
  IonFabButton,
  IonInput,
  IonPage,
  IonToast,
  IonBackButton,
  isPlatform,
  IonSpinner,
} from "@ionic/react";
import {
  atOutline,
  lockOpenOutline,
  arrowBackOutline,
  arrowForwardOutline,
  personOutline,
  callOutline,
} from "ionicons/icons";
import React, { useRef, useState, useEffect } from "react";
import "./Login.scss";
import { useHistory, Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../../BaseUrll";

import { Plugins, Capacitor } from "@capacitor/core";
import "@codetrix-studio/capacitor-google-auth";

import {
  FacebookLoginPlugin,
  FacebookLogin,
} from "@capacitor-community/facebook-login";
import { registerWebPlugin } from "@capacitor/core";
import instance from "../../mocks";

registerWebPlugin(FacebookLogin);

const cookies = new Cookies();

interface loginUI {
  email?: string;
}

const Login: React.FC<loginUI> = (props) => {
  let history = useHistory();
  const socialWrapperRef = useRef(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const credentialRef = useRef(null);
  const signInSignUpContainerRef = useRef(null);
  const signupContainerRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [userData, setUserData] = useState<any>({
    id: null,
    email: null,
    image: null,
    name: null,
    phn_no: null,
    address: null,
    dob: null,
    facebook_id: null,
    google_id: null,
  });
  const [fbData, setFbData] = useState<any>({
    picture: {
      data: {
        url: "",
      },
    },
  });
  const [loading, setLoading] = useState(false);

  async function googleSignIn() {
    const result = await Plugins.GoogleAuth.signIn();

    let data = {
      email: result.email,
      google_id: result.id,
      remember_me: false,
      client: "app",
    };

    setName(result.name);
    setEmail(result.email);
    setLoading(true);

    const url = BASE_URL + "/auth/login";
    axios
      .post(url, data)
      .then((res) => {
        let data = {
          id: res.data.user.id,
          email: res.data.user.email,
          image: res.data.user.image,
          name: res.data.user.name,
          phn_no: res.data.user.phn_no,
          address: res.data.user.address,
          dob: res.data.user.dob,
        };
        setUserData(data);
        localStorage.setItem("loves_burger_user", JSON.stringify(data));
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("access_token_loves_burger", res.data.access_token, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        setLoading(false);
        history.push("/main");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          proceedToSignup(result, "google");
        }
      });
  }

  const handleEmailInputChange = (e: any) => {
    setEmail(e.target.value);
    setUserData({
      ...userData,
      email: e.target.value,
    });
  };

  const handlePasswordInputChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleNameInputChange = (e: any) => {
    setName(e.target.value);
    setUserData({
      ...userData,
      name: e.target.value,
    });
  };

  const handlePhoneInputChange = (e: any) => {
    setPhone(e.target.value);
    setUserData({
      ...userData,
      phn_no: e.target.value,
    });
  };

  const fbLogin = async () => {
    setLoading(true);

    const FACEBOOK_PERMISSIONS = ["email", "user_birthday"];
    if (isPlatform("desktop")) {
      const result = await FacebookLogin.login({
        permissions: FACEBOOK_PERMISSIONS,
      });
      const profile = await FacebookLogin.getProfile<{
        email: string;
      }>({
        fields: ["email", "id", "name", "picture.width(1080)", "birthday"],
      });

      loadUserData(profile);
    } else {
      // const { FacebookLogin } = Plugins;
      const result = await Plugins.FacebookLogin.login({
        permissions: FACEBOOK_PERMISSIONS,
      });
      const profile = await Plugins.FacebookLogin.getProfile({
        fields: ["email", "id", "name", "picture.width(1080)", "birthday"],
      });

      loadUserData(profile);
    }
  };

  const loadUserData = async (profile: any) => {
    setLoading(false);
    setFbData(profile);

    setName(profile.name);
    setEmail(profile.email);

    let data = {
      email: profile.email,
      facebook_id: profile.id,
      remember_me: false,
      client: "app",
    };
    const url = BASE_URL + "/auth/login";
    axios
      .post(url, data)
      .then((res) => {
        let data = {
          id: res.data.user.id,
          email: res.data.user.email,
          image: res.data.user.image,
          name: res.data.user.name,
          phn_no: res.data.user.phn_no,
          address: res.data.user.address,
          dob: res.data.user.dob,
        };
        setUserData(data);
        localStorage.setItem("loves_burger_user", JSON.stringify(data));
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("access_token_loves_burger", res.data.access_token, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        setLoading(false);
        history.push("/main");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          proceedToSignup(profile, "facebook");
        }
      });
  };

  const proceedToSignup = (res: any, type: any) => {
    if (type == "google") {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dob = year + "-" + month + "-" + day;

      setUserData({
        ...userData,
        email: res.email,
        image: res.imageUrl,
        name: res.name,
        google_id: res.id,
        dob: dob,
      });

      let signUpContainer: HTMLElement = document.getElementsByClassName(
        "signup-container"
      )[0] as HTMLElement;
      let credentialContainer: HTMLElement = document.getElementsByClassName(
        "credential-container"
      )[0] as HTMLElement;
      let drawingBuffer: HTMLElement = document.getElementsByClassName(
        "social-login-wrapper"
      )[0] as HTMLElement;

      drawingBuffer.style.display = "none";
      credentialContainer.style.display = "none";
      signUpContainer.style.display = "block";

      contentRef.current && contentRef.current.scrollToBottom();
    } else {
      var d = new Date(res.birthday),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dob = year + "-" + month + "-" + day;

      setUserData({
        ...userData,
        email: res.email,
        image: res.picture.data.url,
        name: res.name,
        facebook_id: res.id,
        dob: dob,
      });

      let signUpContainer: HTMLElement = document.getElementsByClassName(
        "signup-container"
      )[0] as HTMLElement;
      let credentialContainer: HTMLElement = document.getElementsByClassName(
        "credential-container"
      )[0] as HTMLElement;
      let drawingBuffer: HTMLElement = document.getElementsByClassName(
        "social-login-wrapper"
      )[0] as HTMLElement;

      drawingBuffer.style.display = "none";
      credentialContainer.style.display = "none";
      signUpContainer.style.display = "block";

      contentRef.current && contentRef.current.scrollToBottom();
    }
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        if (window.location.pathname === "/") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        } else if (window.location.pathname === "/login") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        }
      });
    }
  }, []);

  const handleLogin = () => {
    if (email == "") {
      setMessage("Email must be provided");
      setShowToast(true);
      return;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setMessage("Invalid Email");
      setShowToast(true);
      return;
    }

    if (password == "") {
      setMessage("Password is required");
      setShowToast(true);
      return;
    }

    setLoading(true);
    let remember_me = false;
    let client = "app";

    const url = BASE_URL + "/auth/login";
    if (mode === "demo") {
      instance.post("/login").then((response) => {
        localStorage.setItem(
          "loves_burger_user",
          JSON.stringify(response.data.user)
        );
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("access_token_loves_burger", response.data.access_token, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        setLoading(false);
        history.push("/main");
      });
    } else {
      axios
        .post(url, { email, password, remember_me, client })
        .then((res) => {
          localStorage.setItem(
            "loves_burger_user",
            JSON.stringify(res.data.user)
          );
          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          cookies.set("access_token_loves_burger", res.data.access_token, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          setLoading(false);
          history.push("/main");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSignUp = () => {
    if (name == "") {
      setMessage("Name must be provided");
      setShowToast(true);
      return;
    } else if (!/^[a-zA-Z ]+$/.test(name)) {
      setMessage("Name only contain alphabets");
      setShowToast(true);
      return;
    }

    if (email == "") {
      setMessage("Email must be provided");
      setShowToast(true);
      return;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setMessage("Invalid Email");
      setShowToast(true);
      return;
    }

    if (password == "") {
      setMessage("Password is required");
      setShowToast(true);
      return;
    } else if (!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(password)) {
      setMessage("Password must be 6 to 20 characters long");
      setShowToast(true);
      return;
    }

    if (phone == "") {
      setMessage("Phone number is required");
      setShowToast(true);
      return;
    } else if (!/^(?:0)?(?:\d\s?){9,10}$/.test(phone)) {
      setMessage("Invalid phone number");
      setShowToast(true);
      return;
    }

    const url = BASE_URL + "/auth/register";
    if (mode === "demo") {
      instance.post("/auth/register").then((response) => console.log(response));
    }
    axios
      .post(url, {
        email: userData.email,
        password: password,
        name: userData.name,
        phn_no: "+44" + userData.phn_no,
        google_id: userData.google_id,
        facebook_id: userData.facebook_id,
        dob: userData.dob,
        image: userData.image,
      })
      .then((res) => {
        localStorage.setItem("loves_burger_user", JSON.stringify(userData));
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("access_token_loves_burger", res.data.access_token, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        let socialLoginWrapper: HTMLElement = document.getElementsByClassName(
          "social-login-wrapper"
        )[0] as HTMLElement;
        let signUpContainer: HTMLElement = document.getElementsByClassName(
          "signup-container"
        )[0] as HTMLElement;
        let signInSignUpContainer: HTMLElement =
          document.getElementsByClassName("signin-btn-group")[0] as HTMLElement;

        socialLoginWrapper.style.display = "block";
        signUpContainer.style.display = "none";
        signInSignUpContainer.style.display = "none";
        contentRef.current && contentRef.current.scrollToTop();
        history.push("/main/tab1");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (cookies.get("access_token_loves_burger") == undefined) {
    return (
      <IonPage>
        <IonContent className="login-container" ref={contentRef}>
          <div className="login-banner">
            <h1 className="ion-text-center">Loves Burger</h1>
            <div className="banner-bottom">
              <div className="banner-quote">
                <p>
                  You can't record and album called 'Meat Is Murder' and slip
                  out for a burger.
                </p>
                <span>~ Hugh L.</span>
              </div>

              <div
                ref={signInSignUpContainerRef}
                className="signin-btn-group ion-justify-content-end"
                style={{ display: "none" }}
              >
                {/* <a href="" className="sign-in-btn">SIGN IN</a>
                                <a href="">SIGN UP</a> */}
                <p
                  className="sign-in-btn"
                  onClick={() => {
                    let signUpContainer: HTMLElement =
                      document.getElementsByClassName(
                        "signup-container"
                      )[0] as HTMLElement;
                    let credentialContainer: HTMLElement =
                      document.getElementsByClassName(
                        "credential-container"
                      )[0] as HTMLElement;

                    credentialContainer.style.display = "block";
                    signUpContainer.style.display = "none";

                    contentRef.current && contentRef.current.scrollToBottom();
                  }}
                >
                  SIGN IN
                </p>
                <p
                  onClick={() => {
                    let signUpContainer: HTMLElement =
                      document.getElementsByClassName(
                        "signup-container"
                      )[0] as HTMLElement;
                    let credentialContainer: HTMLElement =
                      document.getElementsByClassName(
                        "credential-container"
                      )[0] as HTMLElement;

                    credentialContainer.style.display = "none";
                    signUpContainer.style.display = "block";

                    contentRef.current && contentRef.current.scrollToBottom();

                    setEmail("");
                    setPassword("");
                    setName("");
                  }}
                >
                  SIGN UP
                </p>
              </div>
            </div>
          </div>
          {loading ? (
            <IonSpinner className="load-spinner" name="crescent" />
          ) : (
            ""
          )}
          <div ref={socialWrapperRef} className="social-login-wrapper">
            <h4 className="ion-text-center ion-text-uppercase">
              sign in to continue
            </h4>
            <ul>
              <li>
                <IonImg
                  src={require("../../assets/icon/facebook.png").default}
                  alt=""
                  onClick={() => {
                    //signInWithFB();
                    fbLogin();
                  }}
                />
              </li>
              {/* <li><a href=""><IonImg src={require("../../assets/icon/twitter.png").default} alt=""/></a></li> */}
              {isPlatform("android") ? (
                <li>
                  <IonImg
                    onClick={() => {
                      googleSignIn();
                    }}
                    src={require("../../assets/icon/google.png").default}
                    alt=""
                  />
                </li>
              ) : (
                ""
              )}
              <li>
                <a
                  onClick={(e) => {
                    let drawingBuffer: HTMLElement =
                      document.getElementsByClassName(
                        "social-login-wrapper"
                      )[0] as HTMLElement;
                    let credentialContainer: HTMLElement =
                      document.getElementsByClassName(
                        "credential-container"
                      )[0] as HTMLElement;
                    let signInSignUpContainer: HTMLElement =
                      document.getElementsByClassName(
                        "signin-btn-group"
                      )[0] as HTMLElement;

                    drawingBuffer.style.display = "none";
                    credentialContainer.style.display = "block";
                    signInSignUpContainer.style.display = "flex";
                    contentRef.current && contentRef.current.scrollToBottom();

                    setEmail("");
                    setPassword("");
                    setName("");
                  }}
                >
                  EMAIL
                </a>
              </li>
            </ul>
          </div>

          {/*Sign up and Login form container start*/}
          <div className="login-signup-form">
            {/*    /!*Login form start*!/*/}
            <div
              ref={credentialRef}
              className="credential-container"
              style={{ display: "none" }}
            >
              <form action="">
                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={atOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      placeholder="Email Address"
                      onIonChange={(e) => {
                        handleEmailInputChange(e);
                      }}
                    />
                  </IonItem>
                </div>

                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={lockOpenOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      type="password"
                      placeholder="Password"
                      onIonChange={(e) => {
                        handlePasswordInputChange(e);
                      }}
                    ></IonInput>
                  </IonItem>
                </div>
                <div>
                  <p
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      history.push("/forget-password");
                    }}
                  >
                    Forget Password?
                  </p>
                </div>

                <div className="btn-group ion-justify-content-between">
                  <div
                    className="back-to-social"
                    onClick={() => {
                      let socialLoginWrapper: HTMLElement =
                        document.getElementsByClassName(
                          "social-login-wrapper"
                        )[0] as HTMLElement;
                      let credentialContainer: HTMLElement =
                        document.getElementsByClassName(
                          "credential-container"
                        )[0] as HTMLElement;
                      let signInSignUpContainer: HTMLElement =
                        document.getElementsByClassName(
                          "signin-btn-group"
                        )[0] as HTMLElement;

                      socialLoginWrapper.style.display = "block";
                      credentialContainer.style.display = "none";
                      signInSignUpContainer.style.display = "none";
                      contentRef.current && contentRef.current.scrollToTop();
                    }}
                  >
                    <IonIcon icon={arrowBackOutline}></IonIcon>
                    Social Login
                  </div>
                  {/* <IonBackButton text="Social Login" icon={arrowBackOutline} defaultHref="/login" /> */}
                  {/* <a href="">
                                        <IonIcon icon={arrowBackOutline}></IonIcon> 
                                        Social Login
                                    </a> */}
                  <IonFabButton color="primary" onClick={(e) => handleLogin()}>
                    <IonIcon icon={arrowForwardOutline}></IonIcon>
                  </IonFabButton>
                </div>
              </form>
            </div>
            {/*    /!*Login form end*!/*/}

            {/*   /!*Sign up form start*!/*/}
            <div
              ref={signupContainerRef}
              className="signup-container"
              style={{ display: "none" }}
            >
              <form action="">
                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={personOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      placeholder="Name"
                      value={name}
                      onIonChange={(e) => {
                        handleNameInputChange(e);
                      }}
                    />
                  </IonItem>
                </div>

                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={atOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      placeholder="Email Address"
                      value={email}
                      onIonChange={(e) => {
                        handleEmailInputChange(e);
                      }}
                    />
                  </IonItem>
                </div>

                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={lockOpenOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      placeholder="Password"
                      type="password"
                      onIonChange={(e) => {
                        handlePasswordInputChange(e);
                      }}
                    ></IonInput>
                  </IonItem>
                </div>

                <div className="input-group ion-align-items-center ion-margin-bottom">
                  <IonIcon icon={callOutline}></IonIcon>
                  <IonItem>
                    <IonInput
                      placeholder="E.g +44 7222 555 555"
                      type="text"
                      onIonChange={(e) => {
                        handlePhoneInputChange(e);
                      }}
                    ></IonInput>
                  </IonItem>
                </div>

                <div className="btn-group ion-justify-content-between">
                  <div
                    className="back-to-social"
                    onClick={() => {
                      let socialLoginWrapper: HTMLElement =
                        document.getElementsByClassName(
                          "social-login-wrapper"
                        )[0] as HTMLElement;
                      let signUpContainer: HTMLElement =
                        document.getElementsByClassName(
                          "signup-container"
                        )[0] as HTMLElement;
                      let signInSignUpContainer: HTMLElement =
                        document.getElementsByClassName(
                          "signin-btn-group"
                        )[0] as HTMLElement;

                      socialLoginWrapper.style.display = "block";
                      signUpContainer.style.display = "none";
                      signInSignUpContainer.style.display = "none";
                      contentRef.current && contentRef.current.scrollToTop();
                    }}
                  >
                    <IonIcon icon={arrowBackOutline}></IonIcon>
                    Social Login
                  </div>
                  <IonFabButton color="primary" onClick={(e) => handleSignUp()}>
                    <IonIcon icon={arrowForwardOutline}></IonIcon>
                  </IonFabButton>
                </div>
              </form>
            </div>
            {/*    /!*Sign up form end*!/*/}
          </div>

          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={message}
            duration={700}
            cssClass="loves-burger-toast"
          />
        </IonContent>
      </IonPage>
    );
  } else {
    return <Redirect to="/main/tab1"></Redirect>;
  }
};

export default Login;
