import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonSpinner,
} from "@ionic/react";
import "./FavouritesTab.scss";
import "../Notifications/Notifications.scss";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../../BaseUrll";
import instance from "../../mocks";

const cookies = new Cookies();

const FavouritesTab: React.FC = (props) => {
  const [favouriteItems, setFavouriteItems] = useState([]);
  const [noItems, setNoItems] = useState(false);

  useEffect(() => {
    const url = BASE_URL + "/app/my-favourites";
    if (mode == "demo") {
      instance.get("/app/my-favourites").then((response) => {
        if (response.data.data.length <= 0) {
          setNoItems(true);
        }
        setFavouriteItems(response.data.data);
      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
          },
        })
        .then((res) => {
          setFavouriteItems(res.data.data);
          if (res.data.data.length <= 0) {
            setNoItems(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useIonViewWillEnter(() => {
    const url = BASE_URL + "/app/my-favourites";
    
    if (mode == "demo") {
        instance.get("/app/my-favourites").then((response) => {
          if (response.data.data.length <= 0) {
            setNoItems(true);
          }
          setFavouriteItems(response.data.data);
        });
      } else {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
        },
      })
      .then((res) => {
        setFavouriteItems(res.data.data);
        if (res.data.data.length <= 0) {
          setNoItems(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  });
  let history = useHistory();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Favourites</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList className="side-avatar-list">
          {favouriteItems.length > 0
            ? favouriteItems.map((item: any, key: any) => {
                return (
                  <IonItem
                    key={key}
                    onClick={() => {
                      localStorage.setItem(
                        "selected_item",
                        JSON.stringify(item)
                      );
                      history.push("/add-cart/" + item.id);
                    }}
                  >
                    <IonAvatar slot="start">
                      {/* <img src={require(item.image_url).default} /> */}
                      <IonImg src={item.image_url} />
                    </IonAvatar>
                    <IonLabel>
                      <h2 className="">{item.name}</h2>
                      {/* <p>Grilled chicken, cheese, onion</p> */}
                    </IonLabel>
                  </IonItem>
                );
              })
            : ""}
        </IonList>
        {favouriteItems.length == 0 && noItems ? (
          <div className="container">
            <IonImg
              src={require("../../assets/icon/favorites.png").default}
              className="icon-img"
            />
            <IonText>You don't have any favourites</IonText>
          </div>
        ) : favouriteItems.length == 0 ? (
          <div className="ion-padding-start">
            <IonSpinner name="crescent" />
          </div>
        ) : (
          ""
        )}
      </IonContent>
    </IonPage>
  );
};

export default FavouritesTab;
