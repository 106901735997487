import React, {useState} from "react";
import {
    IonContent,
    IonHeader,
    IonAvatar,
    IonPage,
    IonText,
    IonButtons,
    IonList,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonItem,
    IonLabel,
    IonImg, IonRadio, IonRadioGroup, IonListHeader, IonTabButton, IonButton, IonAlert, IonBackButton
} from '@ionic/react';
import { useHistory, Link } from "react-router-dom";
import {arrowBackOutline, checkmarkCircleOutline} from 'ionicons/icons';
import './Payment.scss';
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrll";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const Payment: React.FC = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [selected, setSelected] = useState<string>('cash on delivery');
    const alertImage = require("../../assets/icon/check-mark.png").default;

    let history = useHistory();

    const confirmOrder = () =>{
        let local_cart:any = localStorage.getItem('loves_burger_cart_final');
        local_cart = JSON.parse(local_cart);
        const url = BASE_URL + "/app/place-order";
        axios
        .post(url, local_cart, {
            headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
        })
        .then((res) => {
            if(res.data.status){
                setShowAlert(true);
                let cart:any = [];
                localStorage.setItem('loves_burger_cart', JSON.stringify(cart));
                localStorage.setItem('loves_burger_cart_final', JSON.stringify(cart));
                history.push('/transactions');
            } 
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        <IonBackButton text="" icon={arrowBackOutline} />
                    </IonButtons>
                    <IonTitle>Payment</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="payment-container ion-padding">
                <IonList>
                    <IonRadioGroup value={selected} onIonChange={(e) => {
                        setSelected(e.detail.value)
                    }}>
                        <IonListHeader>
                            <IonLabel className="ion-text-center">Choose your payment method</IonLabel>
                        </IonListHeader>

                        <IonItem>
                            <IonRadio slot="start" value="cash on delivery"/>
                            <IonLabel class="ion-text-center ion-text-capitalize">cash on delivery</IonLabel>
                            <IonImg src={require("../../assets/icon/credit.png").default}/>
                        </IonItem>

                        {/* <IonItem>
                            <IonRadio slot="start" value="credit card"/>
                            <IonLabel class="ion-text-center ion-text-capitalize">credit card</IonLabel>
                            <IonImg src={require("../../assets/icon/creditCard.png").default}/>
                        </IonItem>

                        <IonItem>
                            <IonRadio slot="start" value="paypal"/>
                            <IonLabel class="ion-text-center ion-text-capitalize">paypal</IonLabel>
                            <IonImg src={require("../../assets/icon/paypal.png").default}/>
                        </IonItem> */}

                    </IonRadioGroup>
                </IonList>

                <IonButton onClick={() => confirmOrder()} expand="block">Pay</IonButton>
            </IonContent>

            <IonAlert
                isOpen = {showAlert}
                onDidDismiss = {() => setShowAlert(false)}
                cssClass ='order-alert'
                message = {`<img src = "${alertImage}"/> 
                 <p>Your order is Placed!</p>`}
            />

        </IonPage>
    );
};

export default Payment;
