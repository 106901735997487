import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default class AdminRoute extends Component {
    render() {
      const { children, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={() => {
            // if(this.props.path === '/login' && cookies.get("access_token_loves_burger") !== undefined){
            //     (
            //         <Redirect to ="/main" />
            //     )
            // }else{
            //     return children;
            // }
            return cookies.get("access_token_loves_burger") !== undefined ? (
              children
            ) : (
              <Redirect
                to="/login"
              />
            );
          }}
        ></Route>
      );
    }
  }