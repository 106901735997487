import {
    IonSlides,
    IonSlide,
    IonImg,
    IonCard,
    IonText,
    IonBadge,
    IonButton,
    IonSpinner
} from "@ionic/react";

import React from "react";
import './MenuSlider.scss';
import './CustomSlider.scss'


const Options = {
    slidesPerView: 3.5,
    spaceBetween: 10,
};

// interface apiData{
//     [index: number]: { created_at: string; id: number; name: any };
// }

interface Props{
    itemsWithCategory: any;
    sendDataToParent: any;
}

const MenuSlider: React.FC<Props> = (props) => {
    console.log(props.itemsWithCategory)
    return (
        <div className="menu-slider">
            {props.itemsWithCategory.length != 0?
                (
                    <IonSlides options={Options} className="ion-padding-start">
                        {props.itemsWithCategory.map((item: any, key: number) =>{
                            return (
                                <IonSlide key={key}>
                                    <IonButton expand="block" onClick={()=>{
                                        props.sendDataToParent(key);
                                    }}>{item.name}</IonButton>
                                </IonSlide>
                            )
                        })}
                    </IonSlides>
                )
                :
                (
                    <div className="ion-padding-start">
                        <IonSpinner name="crescent" />
                    </div>
                )
            }
        </div>
    );
};

export default MenuSlider;
