import React, { useEffect, useState } from "react";
import {
    IonContent,
    IonHeader,
    IonAvatar,
    IonPage,
    IonText,
    IonButtons,
    IonList,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonItem,
    IonLabel,
    IonImg,
    IonBackButton,
    useIonViewWillEnter
} from '@ionic/react';
import {arrowBackOutline, star, create, notifications} from 'ionicons/icons';
import './Notifications.scss';

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../BaseUrll";

import Cookies from "universal-cookie";
import { useHistory, Link } from "react-router-dom";

const cookies = new Cookies();

const SingleNotifications: React.FC = (props) => {
    let history = useHistory();

    var index:any = window.location.pathname;
    index = index.split('/');
    index = index[index.length -1];

    let [notification, setNotification] = useState<any>(null);
    const [count, setCount] = useState(44);

    useEffect(() =>{

    }, []);

    useIonViewWillEnter(()=>{
        setCount(Math.random());
        let notificationss:any = localStorage.getItem('loves_burger_notifications');
        notificationss = JSON.parse(notificationss);

        setNotification(notificationss[index]);
    });

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        <IonBackButton text="" icon={arrowBackOutline} />
                    </IonButtons>
                    <IonTitle>Notification</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="search-container ion-padding">
                <h3>{notification && notification.notification.title? notification.notification.title : ''}</h3>
                <p>{notification && notification.notification.description? notification.notification.description : ''}</p>
            </IonContent>
        </IonPage>
    );
}

export default SingleNotifications;
