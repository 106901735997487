import React from "react";
import {Redirect, Route} from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import FavouritesTab from './pages/Tabs/FavouritesTab';
import Login from './pages/Login/Login';
import Notifications from "./pages/Notifications/Notifications";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUS";
import Menu from "./pages/Menu/Menu";
import Search from "./pages/Search/Search";
import Payment from "./pages/Payment/Payment";
import Transactions from "./pages/Transactions/Transactions";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import CartConfirm from "./pages/CartConfirm/CartConfirm";
import AddCart from "./pages/AddCart/AddCart";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";

import AppRoute from "./AppRoute";

/* Custom CSS*/
import './pages/Tabs/Tabs.scss';
import './theme/basic.scss';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import Tabs from './pages/Tabs/Tabs';
import SingleNotification from "./pages/Notifications/SingleNotifiction";


const App: React.FC = () => (
    <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>

                {/* <AppRoute path="/main" exact>
                    <Tabs/>
                </AppRoute> */}

                <Route path="/main" component={Tabs} />

                <Route exact path="/login">
                    <Login/>
                </Route>

                <Route exact path="/forget-password">
                    <ForgetPassword/>
                </Route>

                <Route exact path="/contact-us">
                    <ContactUs/>
                </Route>

                <Route exact path="/about-us">
                    <AboutUs/>
                </Route>

                <Route exact path="/menu">
                    <Menu/>
                </Route>

                <Route exact path="/payment">
                    <Payment/>
                </Route>

                <Route exact path="/search">
                    <Search/>
                </Route>

                <Route exact path="/notifications">
                    <Notifications/>
                </Route>

                <Route exact path="/single-notification/:id">
                    <SingleNotification/>
                </Route>

                <Route exact path="/transactions">
                    <Transactions/>
                </Route>

                <Route exact path="/cart-confirm">
                    <CartConfirm/>
                </Route>

                <Route exact path="/add-cart/:id">
                    <AddCart/>
                </Route>

                <Route exact path="/account-settings">
                    <AccountSettings></AccountSettings>
                </Route>

                <Route exact path="/" render={() => <Redirect to="/login"/>}/>

                {/* <Redirect to="/login" /> */}
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>
);

export default App;
