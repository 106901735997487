import { Redirect, Route } from "react-router-dom";
import {
  IonSlides,
  IonSlide,
  IonImg,
  IonCard,
  IonText,
  IonCardTitle,
  IonCardContent,
  IonBadge,
  useIonViewWillEnter,
  IonSpinner,
} from "@ionic/react";

import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import "./CustomSlider.scss";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../BaseUrll";
import instance from "../mocks";

const cookies = new Cookies();

const slideOpts = {
  slidesPerView: 1.5,
};

const CustomSlider: React.FC = () => {
  const [popularItems, setPopularItems] = useState([]);
  const [count, setCount] = useState<any>("");

  let history = useHistory();

  useEffect(() => {
    const url = BASE_URL + "/app/popular-items";
    if (mode === "demo") {
      instance.get("/app/popular-items").then((response) => {
        setPopularItems(response.data.data);
      });
    } else {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
          },
        })
        .then((res) => {
          setPopularItems(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  // useIonViewWillEnter(() =>{
  //     setCount(Math.random());
  // });

  return (
    <div className="home-slider">
      <h4 className="ion-text-uppercase">special offers</h4>

      {popularItems.length != 0 ? (
        <IonSlides options={slideOpts}>
          {popularItems.map((item: any, index: any) => {
            return (
              <IonSlide
                key={index}
                onClick={() => {
                  localStorage.setItem("selected_item", JSON.stringify(item));
                  history.push("/add-cart/" + item.id);
                }}
              >
                <IonCard>
                  <IonImg src={item.image_url} />
                  <IonBadge>$ {item.price}</IonBadge>
                  <IonText className="ion-text-capitalize ion-text-left">
                    {item.name}
                  </IonText>
                </IonCard>
              </IonSlide>
            );
          })}
        </IonSlides>
      ) : (
        <div className="ion-padding-start">
          <IonSpinner name="crescent" />
        </div>
      )}
      {/* <IonSlide onClick={() =>{
                    history.push('/add-cart');
                }}>
                    <IonCard>
                        <IonImg src={require("../assets/images/image-1.jpg").default}/>
                        <IonBadge>$ 4.5</IonBadge>
                        <IonText className="ion-text-capitalize ion-text-left">spicy fire house</IonText>
                    </IonCard>
                </IonSlide>
                <IonSlide onClick={() =>{
                    history.push('/add-cart');
                }}>
                    <IonCard>
                        <IonImg src={require("../assets/images/image-1.jpg").default}/>
                        <IonBadge>$ 4.5</IonBadge>
                        <IonText className="ion-text-capitalize ion-text-left">spicy fire house</IonText>
                    </IonCard>
                </IonSlide> */}
    </div>
  );
};

export default CustomSlider;
