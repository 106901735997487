import React, { useState, useEffect } from "react";
import {IonReactRouter} from "@ionic/react-router";
import {IonApp, IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonPage, IonBadge, useIonViewWillEnter} from "@ionic/react";
import {Route, Redirect} from "react-router-dom";

import AppRoute from "../../AppRoute";

// Tabs components
import Tab1 from "./Tab1";
import FavouritesTab from "./FavouritesTab";
import CartTab from "./CartTab";
import UserTab from "./UserTab";
import AddCart from '../../pages/AddCart/AddCart';
import {cart, cartOutline, fastFoodOutline, heartOutline, personOutline} from "ionicons/icons";

const Tabs: React.FC = () => {
    const [cart, setCart] = useState([]);
    const [clicked, setClicked] = useState(false);

    useEffect(()=>{
        
    },[]);

    useIonViewWillEnter(() =>{
        let cartt:any = localStorage.getItem('loves_burger_cart');
        cartt = JSON.parse(cartt);
        setCart(cartt);
    });

    const handleClick = () =>{
        setClicked(true);
        console.log('here');
    }

    return(
        <div>
            <IonTabs>
                    <IonRouterOutlet>
                        <AppRoute path="/main/tab1" exact>
                            <Tab1/>
                        </AppRoute>

                        <AppRoute path="/main/favourites" exact>
                            <FavouritesTab/>
                        </AppRoute>

                        <AppRoute path="/main/cart" exact>
                            <CartTab/>
                        </AppRoute>

                        <AppRoute path="/main/user" exact>
                            <UserTab/>
                        </AppRoute>

                        {/* <Route exact path="/main/tab1">
                            <Tab1/>
                        </Route>

                        <Route exact path="/main/favourites">
                            <FavouritesTab/>
                        </Route>

                        <Route path="/main/cart">
                            <CartTab/>
                        </Route>

                        <Route exact path="/main/user">
                            <UserTab/>
                        </Route> */}

                        <Redirect exact from={"/main"} to={"/main/tab1"} />
                        

                        {/* <Route exact path="/add-cart" component={AddCart} /> */}

                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="tab1" href="/main/tab1">
                            <IonIcon icon={fastFoodOutline}/>
                        </IonTabButton>

                        <IonTabButton tab="tab2" href="/main/favourites">
                            <IonIcon icon={heartOutline}/>
                        </IonTabButton>

                        <IonTabButton className="notification-button" tab="tab3" href="/main/cart" >
                                <IonIcon icon={cartOutline}>
                                </IonIcon>
                                {cart && cart.length > 0? 
                                    <IonBadge className="notifications-badge" color="danger"></IonBadge>
                                    :
                                    ''
                                }
                        </IonTabButton>

                        <IonTabButton tab="tab4" href="/main/user">
                            <IonIcon icon={personOutline}/>
                        </IonTabButton>
                    </IonTabBar>
            </IonTabs>
        </div>
    );
}



export default Tabs;