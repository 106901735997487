import React, { useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonIcon,
    IonButton,
    IonImg,
    IonText, IonFabButton,
    useIonViewWillEnter
} from '@ionic/react';
import {checkmarkOutline, trashOutline} from 'ionicons/icons';
import { useHistory, Link } from "react-router-dom";
import './CartTab.scss';


const CartTab: React.FC = (props) => {
    const [count, setCount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [cart, setCart] = useState<any>([]);

    let history = useHistory();

    useIonViewWillEnter(() =>{
        let local_cart:any = localStorage.getItem('loves_burger_cart');
        local_cart = JSON.parse(local_cart);
        setCart(local_cart);

        let total_cost:any = 0;
        if(local_cart){
            local_cart.map((item:any, index:any)=>{
                total_cost += parseInt(item.total_price);
            });
            setTotalCost(total_cost);
        }
    });

    const handleRemoveItem = (index:any) =>{
        setCount(Math.random());
        let cartt = cart;
        cartt.splice(index, 1);
        let total_cost:any = 0;
        if(cartt){
            cartt.map((item:any, index:any)=>{
                total_cost += parseInt(item.total_price);
            });
            setTotalCost(total_cost);
        }
        localStorage.setItem('loves_burger_cart', JSON.stringify(cartt));
        setCart(cartt);
    }

    const cartCheckOut = () =>{
        let local_cart:any = localStorage.getItem('loves_burger_cart');
        local_cart = JSON.parse(local_cart);
        let final_cart = {
            items: local_cart
        };
        localStorage.setItem('loves_burger_cart_final', JSON.stringify(final_cart));

        history.push('/payment');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Cart</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                {cart == null || cart.length <= 0?
                    <div className="container">
                        <IonImg src={require("../../assets/icon/cart.png").default} className="icon-img"/>
                        <IonText>No items added yet</IonText>
                    </div>
                    :
                    <>
                    <div className="cart-section">
                        <div className="cart-header">
                            <h1 className="ion-text-center">Total cost</h1>
                            <h2 className="ion-text-center">${totalCost}</h2>
                        </div>

                        <div className="cart-container">
                            {cart && cart.map((item:any, index:any)=>{
                                return(
                                    <div key={index} className="cart-row">
                                        <div className="cart-item">
                                            <h3 className="ion-text-uppercase">{item.food_item_name}</h3>
                                            <div className="item-quantity ion-text-uppercase">quantity: <span>{item.food_item_quantity}</span></div>
                                            <div className="item-price ion-text-uppercase">cost: <span>${item.total_price}</span></div>
                                            <div><IonIcon onClick={()=>{
                                                handleRemoveItem(index);
                                            }} icon={trashOutline}/></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="btn-check">
                        {cart.length > 0? <IonFabButton color="primary" onClick={() =>{cartCheckOut()}}><IonIcon icon={checkmarkOutline}/></IonFabButton>:''}
                    </div>
                    </>
                }
            </IonContent>
        </IonPage>
    );
}

export default CartTab;
