import React from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonButtons,
    IonImg,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonBackButton
} from '@ionic/react';
import {arrowBackOutline, star, create} from 'ionicons/icons';
import './AboutUs.scss';


class AboutUs extends React.Component {

    render() {
        return (
            <IonPage>
                <IonHeader className="header-center">
                    <IonToolbar color="primary">
                        <IonButtons slot="start" className="ion-margin-start">
                            {/* <IonIcon slot="start" icon={arrowBackOutline}/> */}
                            <IonBackButton text="" icon={arrowBackOutline} />
                        </IonButtons>
                        <IonTitle>About Us</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen className="ion-padding">
                    <div className="about-us">
                        <div className="about-detail">
                            <IonImg src={require("../../assets/icon/icon.png").default}/>
                            <h6 className="ion-text-capitalize ion-text-center">Loves Burger <br/>The simplest way to
                                place an order</h6>
                        </div>

                        <p className="ion-text-justify">
                            Loves Burger is and online burger ordering service in United kingdom (UK). We offer the
                            quickest, easiest, most reliable burger ordering experience.
                        </p>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

export default AboutUs;
