import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonAvatar,
    IonPage,
    IonSearchbar,
    IonButtons,
    IonList,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonItem,
    IonLabel,
    IonImg, IonRadio, IonRadioGroup, IonListHeader, IonTabButton, IonButton, IonBackButton
} from '@ionic/react';
import {arrowBackOutline} from 'ionicons/icons';
import './Search.scss';
import { useHistory } from "react-router-dom";

// export const SearchList = [
//     {
//         id: "1",
//         title: "Business one",
//         imgUrl: "../assets/icon/paypal.png"
//     },
//     {
//         id: "2",
//         title: "Computing",
//         imgUrl: "../assets/icon/paypal.png"
//     },
//     {
//         id: "3",
//         title: "Connections",
//         imgUrl: "../assets/icon/paypal.png"
//     },
//     {
//         id: "4",
//         title: "Connections one",
//         imgUrl: "../assets/icon/paypal.png"
//     },
//     {
//         id: "5",
//         title: "Connections",
//         imgUrl: "../assets/icon/paypal.png"
//     },
// ];




const Search: React.FC = () => {
    

    let history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [filteredSearch, setFilteredSearch] = useState<any>([]);
    const [SearchList, setSearchList] = useState<any>(null);
    
    useEffect(() => {
        if(localStorage.getItem('loves_burger_items_search_array') != null && localStorage.getItem('loves_burger_items_search_array') != undefined){
            let items:any = localStorage.getItem('loves_burger_items_search_array');
            const SearchList:any = JSON.parse(items);
            setSearchList(SearchList);
        }

        if(SearchList){
            let tempSearchResult:any = [];
            SearchList.map((item:any)=>{
                if(item.name.toLowerCase().includes(searchText.toLowerCase())){
                    tempSearchResult.push(item);
                }
            });
            setFilteredSearch(tempSearchResult);
        }
        
    },[searchText]);

    return (
        <IonPage>
            <IonHeader className="header-center">
                <IonToolbar color="primary">
                    <IonButtons slot="start" className="ion-margin-start">
                        <IonBackButton text="" icon={arrowBackOutline} />
                        {/* <IonIcon slot="start" icon={arrowBackOutline} onClick={() =>{
                            history.goBack();
                        }}/> */}
                    </IonButtons>
                    <IonTitle>Search</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="search-container ion-padding">
                <h1>Hello, I am Tasty. What <br/> are you looking for?</h1>
                <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} showCancelButton="focus" placeholder="What are you looking for?"></IonSearchbar>
                <IonList className="side-avatar-list">
                    {filteredSearch.map((search:any) => (
                        <IonItem key={search.id} onClick={()=>{
                            localStorage.setItem('selected_item', JSON.stringify(search));
                            history.push('/add-cart/'+search.id);
                        }}>
                            <IonAvatar slot="start">
                                <img src={search.image_url} />
                            </IonAvatar>
                            <IonLabel>
                                <h2 className="">{search.name}</h2>
                            </IonLabel>
                        </IonItem>
                        ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Search;
