import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButton,
  IonTitle,
  IonCard,
  IonImg,
  IonBadge,
  IonText,
  IonSlide,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonCheckbox,
  IonItem,
  IonList,
  IonItemDivider,
  IonListHeader,
  CreateAnimation,
  IonBackButton,
  IonToast,
  useIonViewWillEnter,
  IonSpinner,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import {
  addOutline,
  arrowBackOutline,
  chevronDownOutline,
  heart,
  heartOutline,
  notificationsOutline,
  removeOutline,
  searchOutline,
} from "ionicons/icons";
import "./AddCart.scss";
import { useHistory, match } from "react-router-dom";
import Cookies from "universal-cookie";

//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../../BaseUrll";
import instance from "../../mocks";

interface DetailParams {
  id: string;
}
interface DetailsProps {
  match?: match<DetailParams>;
}

const cookies = new Cookies();

const AddCart: React.FC<DetailsProps> = () => {
  const [count, setCount] = useState(44);
  const [cart, setCart] = useState<any>({});
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [imageBg, setImageBg] = useState("");
  const [itemId, setItemId] = useState("");
  const [isFavourite, setIsFavourite] = useState(false);
  const [item, setItem] = useState<any>([]);
  const [variations, setVariations] = useState<any>([]);
  const [properties, setProperties] = useState<any>([]);
  const [itemPrice, setItemPrice] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [selectedVariation, setSelectedVariation] = useState<any>({
    id: "",
    name: "",
    price: "",
  });
  const [selectedProperty, setSelectedProperty] = useState<any>([]);

  let history = useHistory();

  useEffect(() => {}, []);

  useIonViewWillEnter(() => {
    // var id:any = window.location.pathname;
    // id = id.split('/');
    // id = id[id.length -1];

    // const url = BASE_URL + "/app/item/"+id;
    // axios
    // .get(url, {
    //     headers: { Authorization: `Bearer ${cookies.get('access_token_loves_burger')}` },
    // })
    // .then((res) => {
    //     setIsFavourite(res.data.data.is_favourite);
    // })
    // .catch((error) => {
    //     console.log(error);
    // });

    const itemSettings = async () => {
      var id: any = window.location.pathname;
      id = id.split("/");
      id = id[id.length - 1];

      let selected: any = localStorage.getItem("selected_item");
      selected = JSON.parse(selected);

      setItem(selected);

      setImageBg(selected.image_url);
      await setItemId(selected.id);

      let bg: HTMLElement = document.getElementsByClassName(
        "add-cart-container"
      )[0] as HTMLElement;
      bg.style.background =
        "url(" + selected.image_url + ") top center no-repeat";

      const url = BASE_URL + "/app/item/" + selected.id;

      if (mode === "demo") {
        instance.get("/app/item/12").then((response) => {
          console.log(response.data);
          setLoading(false);
          setIsFavourite(response.data.data.is_favourite);
          setVariations(response.data.data.food_variations);

          let properties: any = response.data.data.properties;
          if (properties.length) {
            properties.map((item: any, key: any) => {
              item.items.map((property: any, index: any) => {
                property.property_type = item.name;
                if (property.allow_multi_quantity) {
                  property.quantity = 1;
                }
              });
            });
            setProperties(properties);
          }

          if (
            response.data.data.food_variations &&
            response.data.data.food_variations.length > 0
          ) {
            setSelectedVariation({
              id: response.data.data.food_variations[0].variation_id,
              name: response.data.data.food_variations[0].variation.name,
              price: response.data.data.food_variations[0].price,
            });
          }
          if (response.data.data.price) {
            setItemPrice(response.data.data.price);
            setBasePrice(response.data.data.price);
          } else {
            setItemPrice(response.data.data.food_variations[0].price);
          }
        });
      } else {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${cookies.get(
                "access_token_loves_burger"
              )}`,
            },
          })
          .then((res) => {
            setLoading(false);
            setIsFavourite(res.data.data.is_favourite);
            setVariations(res.data.data.food_variations);

            let properties: any = res.data.data.properties;
            if (properties) {
              properties.map((item: any, key: any) => {
                item.items.map((property: any, index: any) => {
                  property.property_type = item.name;
                  if (property.allow_multi_quantity) {
                    property.quantity = 1;
                  }
                });
              });
              setProperties(properties);
            }

            if (
              res.data.data.food_variations &&
              res.data.data.food_variations.length > 0
            ) {
              setSelectedVariation({
                id: res.data.data.food_variations[0].variation_id,
                name: res.data.data.food_variations[0].variation.name,
                price: res.data.data.food_variations[0].price,
              });
            }
            if (res.data.data.price) {
              setItemPrice(res.data.data.price);
              setBasePrice(res.data.data.price);
            } else {
              setItemPrice(res.data.data.food_variations[0].price);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    itemSettings();
  });

  const handleCart = () => {
    let cart: any = localStorage.getItem("loves_burger_cart");
    cart = JSON.parse(cart);

    let cart_item: any = {
      food_item_id: item.id,
      food_item_name: item.name,
      variation: selectedVariation,
      properties: selectedProperty,
      total_price: itemPrice,
      food_item_quantity: 1,
    };

    if (cart != null) {
      let found: boolean = false;
      cart.map((iteem: any, index: any) => {
        if (iteem.food_item_id == item.id) {
          found = true;
          cart.splice(index, 1);
          cart.push(cart_item);
          localStorage.setItem("loves_burger_cart", JSON.stringify(cart));
        }
      });
      if (!found) {
        cart.push(cart_item);
        localStorage.setItem("loves_burger_cart", JSON.stringify(cart));
      }
    } else {
      cart = [];
      cart.push(cart_item);
      // cart.map((iteem:any, index:any)=>{
      //     if(iteem.id == item.id){
      //         cart.splice(index, 1);
      //     }else{
      //         cart.push(cart_item);
      //         localStorage.setItem('loves_burger_cart', JSON.stringify(cart));
      //     }
      // });
      localStorage.setItem("loves_burger_cart", JSON.stringify(cart));
    }
    let selected_item = item;
    selected_item.total_price = itemPrice;
    selected_item.food_item_quantity = 1;
    localStorage.setItem("selected_item", JSON.stringify(selected_item));
    history.replace("/cart-confirm");
  };

  const addToFavourites = () => {
    if (isFavourite) {
      let categoryIndex: any = localStorage.getItem("category_index");
      categoryIndex = JSON.parse(categoryIndex);

      let itemIndex: any = localStorage.getItem("item_index");
      itemIndex = JSON.parse(itemIndex);

      setIsFavourite(false);
      let selected_item = item;
      selected_item.is_favourite = false;
      localStorage.setItem("selected_item", JSON.stringify(selected_item));
    } else {
      setIsFavourite(true);
      let selected_item = item;
      selected_item.is_favourite = true;
      localStorage.setItem("selected_item", JSON.stringify(selected_item));
    }

    const url = BASE_URL + "/app/add-to-favourites/" + itemId;
    if(mode == "demo"){
        instance.post("/app/add-to-favourites/12")
        .then(response => {
            // console.log(response)
            if (response.data.status) {
                setMessage(response.data.message);
                setShowToast(true);
              }
        })
    }
    else{

        axios
          .post(
            url,
            {},
            {
              headers: {
                Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setMessage(res.data.message);
              setShowToast(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  const variationSelectionChange = async (key: any, price: any) => {
    await setSelectedVariation({
      id: variations[key].variation_id,
      name: variations[key].variation.name,
      price: variations[key].price,
    });

    handlePrice(price);
  };

  const handlePrice = (value: any) => {
    let price: any = parseInt(itemPrice) - parseInt(basePrice);
    price += parseInt(value);
    setBasePrice(value);
    setItemPrice(price);
  };

  const handleQuantityChange = (type: string, key: number, index: number) => {
  
    setCount(Math.random());
    let all_properties = properties;

    if (type == "minus" && all_properties[key].items[index].quantity > 1) {
      let quantity = all_properties[key].items[index].quantity - 1;
      all_properties[key].items[index].quantity = quantity;
      setProperties(all_properties);

      let selected_property = selectedProperty;
      selected_property.map((item: any, indexx: any) => {
        if (item.id == all_properties[key].items[index].id) {
          let price: any;
          if (properties[key].items[indexx].quantity) {
            //item.quantity -= 1;
            price = parseInt(itemPrice) - parseInt(item.extra_price);
          } else {
            price = parseInt(itemPrice) - parseInt(item.extra_price);
          }
          setItemPrice(price);
        }
      });
      setSelectedProperty(selected_property);
    } else if (type == "plus") {
        console.log("type: "+ type + " key: "+ key + " index: "+index)
      let old_quantity = all_properties[key].items[index].quantity;
      let quantity = all_properties[key].items[index].quantity + 1;
      all_properties[key].items[index].quantity = quantity;
      setProperties(all_properties);
      

      let selected_property = selectedProperty;
      selected_property.map((item: any, indexx: any) => {
        if (item.id == all_properties[key].items[index].id) {
          let price: any;
          if (item.quantity) {
            //item.quantity += 1;
            price =
              parseInt(itemPrice) -
              old_quantity * parseInt(item.extra_price) +
              item.quantity * parseInt(item.extra_price);
          } else {
            price = parseInt(itemPrice) + parseInt(item.extra_price);
          }
          setItemPrice(price);
        }
      });
      setSelectedProperty(selected_property);
    }
  };

  const handlePropertyCheck = (key: number, indexx: number) => {
    let found = false;
    let selected_property = selectedProperty;
    selected_property.map((item: any, index: any) => {
      if (item.id == properties[key].items[indexx].id) {
        found = true;
        selected_property.splice(index, 1);
        let price: any;
        if (properties[key].items[indexx].quantity) {
          price =
            parseInt(itemPrice) -
            properties[key].items[indexx].quantity *
              parseInt(properties[key].items[indexx].extra_price);
        } else {
          price =
            parseInt(itemPrice) -
            parseInt(properties[key].items[indexx].extra_price);
        }

        setItemPrice(price);
        setSelectedProperty(selected_property);
      }
    });
    if (!found) {
      selected_property.push(properties[key].items[indexx]);
      let price: any;
      if (properties[key].items[indexx].quantity) {
        price =
          parseInt(itemPrice) +
          properties[key].items[indexx].quantity *
            parseInt(properties[key].items[indexx].extra_price);
      } else {
        price =
          parseInt(itemPrice) +
          parseInt(properties[key].items[indexx].extra_price);
      }
      setItemPrice(price);
      setSelectedProperty(selected_property);
    }
  };

  return (
    <IonPage>
      {/*Header Start*/}
      <IonHeader className="header-center">
        <IonToolbar color="primary">
          <IonButtons slot="start" className="ion-margin-start">
            <IonBackButton text="" icon={arrowBackOutline} defaultHref="/" />

            {/* <IonIcon slot="start" icon={arrowBackOutline}/> */}
          </IonButtons>
          <IonButtons slot="end" className="ion-margin-end">
            {isFavourite ? (
              <IonIcon
                slot="end"
                id="one"
                icon={heart}
                onClick={() => {
                  addToFavourites();
                }}
              />
            ) : (
              <IonIcon
                slot="end"
                id="two"
                icon={heartOutline}
                onClick={() => {
                  addToFavourites();
                }}
              />
            )}
            {/* <IonIcon slot="end" icon={heartOutline} onClick={() =>{
                            addToFavourites();
                        }}/> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/*Header End*/}

      <IonContent scrollY={false}>
        <div
          className="add-cart-container"
          style={{
            background:
              "url(" + { imageBg } + ") top center no-repeat !important",
            height: "100%",
          }}
        >
          <CreateAnimation
            duration={450}
            fromTo={[
              {
                property: "transform",
                fromValue: "translateY(100%)",
                toValue: "translateY(0px)",
              },
            ]}
            play={true}
          >
            <div style={{ display: "flex", padding: "20px 15px 20px 15px" }}>
              <IonLabel className="single-item-title">{item.name}</IonLabel>
              <IonLabel className="single-item-price">${itemPrice}</IonLabel>
              {/* {item.price? <IonLabel className='single-item-price'>${item.price}</IonLabel>: ''} */}
            </div>

            <div className="add-cart-drawer ion-padding-top">
              <IonList>
                {loading ? (
                  <IonSpinner name="crescent" />
                ) : item.has_property == "0" && item.has_variation == "0" ? (
                  <IonRadioGroup value={item.price}>
                    <IonItem lines="none">
                      <IonLabel>{item.name}</IonLabel>
                      <IonRadio value={item.price} slot="start" />
                      <IonText>${item.price}</IonText>
                    </IonItem>
                  </IonRadioGroup>
                ) : (
                  ""
                )}

                {!loading && variations.length > 0 ? (
                  <>
                    <IonListHeader>Variations</IonListHeader>
                    <IonRadioGroup
                      value={selectedVariation.price}
                      onIonChange={(e) => {
                        // handlePrice(e.detail.value);
                      }}
                    >
                      {variations.map((item: any, key: any) => {
                        return (
                          <div key={item.variation_id}>
                            <IonItem
                              key={key}
                              lines="none"
                              onClick={() => {
                                variationSelectionChange(key, item.price);
                              }}
                            >
                              <IonLabel>{item.variation.name}</IonLabel>
                              <IonRadio
                                value={item.price}
                                id={key}
                                slot="start"
                              />
                              <IonText>${item.price}</IonText>
                            </IonItem>
                          </div>
                        );
                      })}
                    </IonRadioGroup>
                  </>
                ) : (
                  ""
                )}

                {/* <p style={{display: 'none'}}>{count}</p> */}
                {!loading &&
                  properties &&
                  properties.map((item: any, key: any) => {
                    return (
                      <>
                        <IonListHeader key={key}>{item.name}</IonListHeader>
                        {item.items.map((property: any, index: any) => {
                          return (
                            <>
                              <IonItem key={index} lines="none">
                                <IonLabel>
                                  <h4>{property.name}</h4>
                                  <IonText>${property.extra_price}</IonText>
                                </IonLabel>
                                <IonCheckbox
                                  slot="start"
                                  onClick={() => {
                                    handlePropertyCheck(key, index);
                                  }}
                                />
                                {property.allow_multi_quantity ? (
                                  <div className="item-counter">
                                    <IonButton
                                      onClick={() => {
                                        handleQuantityChange(
                                          "minus",
                                          key,
                                          index
                                        );
                                      }}
                                    >
                                      <IonIcon icon={removeOutline}></IonIcon>
                                    </IonButton>

                                    <IonText
                                      className="ion-align-items-center"
                                      id={index}
                                    >
                                      {property.quantity}
                                    </IonText>
                                    {/* <p className="ion-align-items-center">{property.quantity}</p> */}
                                    <IonButton
                                      onClick={() => {
                                        handleQuantityChange(
                                          "plus",
                                          key,
                                          index
                                        );
                                      }}
                                    >
                                      <IonIcon icon={addOutline}></IonIcon>
                                    </IonButton>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </IonItem>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
              </IonList>
            </div>
          </CreateAnimation>
          <IonButton
            className="btn-default"
            expand="block"
            onClick={() => {
              handleCart();
              //history.push('/cart-confirm')
            }}
          >
            Add to cart
          </IonButton>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={700}
          cssClass="loves-burger-toast"
        />
      </IonContent>
    </IonPage>
  );
};
export default AddCart;
