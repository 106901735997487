import React from "react";
import {
    IonContent,
    IonHeader,
    IonAvatar,
    IonPage,
    IonAlert,
    IonButtons,
    IonButton,
    IonImg,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonItem,
    IonLabel,
    IonBackButton
} from '@ionic/react';
import {arrowBackOutline, star, create} from 'ionicons/icons';
import './ContactUs.scss';


interface Props {}

interface alertPopup {
    showAlert:boolean;
}

class ContactUs extends React.Component<Props, alertPopup> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showAlert: false
        };
    }
    render() {
        return (
            <IonPage>
                <IonHeader className="header-center">
                    <IonToolbar color="primary">
                        <IonButtons slot="start" className="ion-margin-start">
                            {/* <IonIcon slot="start" icon={arrowBackOutline}/> */}
                            <IonBackButton text="" icon={arrowBackOutline} />
                        </IonButtons>
                        <IonTitle>Contact Us</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className="container contact-us">
                        <div className="contact-detail">
                            <IonImg src={require("../../assets/icon/contactUs.jpg").default} className="icon-img"/>
                            <h4>We're happy to Help You!</h4>
                            <p>If you have any complain about our food, contact us</p>
                        </div>
                        <a className="callus-btn" href="tel: 07404665117">Call Us</a>
                        {/* <IonButton onClick={() => {
                            this.setState({
                                showAlert: !this.state.showAlert
                            })}} expand="block">Call Us</IonButton> */}
                    </div>
                </IonContent>
                <IonAlert
                    isOpen={this.state.showAlert}
                    onDidDismiss={() => {
                        this.setState({
                            showAlert: !this.state.showAlert
                        });
                    }}
                    cssClass='call-us-alert'
                    header={'Call Us?'}
                    // subHeader={'Subtitle'}
                    message={'Call +201020100231 ?'}
                    buttons={['Cancel', 'Call']}
                />
            </IonPage>
        );
    }
}

export default ContactUs;
