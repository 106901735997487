import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonHeader,
  IonAvatar,
  IonPage,
  IonText,
  IonButtons,
  IonList,
  IonToolbar,
  IonIcon,
  IonTitle,
  IonItem,
  IonLabel,
  IonImg,
  IonBackButton,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { arrowBackOutline, star, create } from "ionicons/icons";
import "./Notifications.scss";

//axios and base url
import axios from "axios";
import { BASE_URL, mode } from "../../BaseUrll";

import Cookies from "universal-cookie";
import { useHistory, Link } from "react-router-dom";
import instance from "../../mocks";

const cookies = new Cookies();

const Notifications: React.FC = (props) => {
  let history = useHistory();

  let [notifications, setNotifications] = useState<any>([]);
  const [count, setCount] = useState(44);

  useEffect(() => {}, []);

  useIonViewWillEnter(() => {
    let notificationss: any = localStorage.getItem(
      "loves_burger_notifications"
    );
    notificationss = JSON.parse(notificationss);

    // setNotifications(notificationss);
    if (mode == "demo") {
      instance
        .post("/app/notifications")
        .then((response) => {
          console.log(response);
          localStorage.setItem(
            "loves_burger_notifications",
            JSON.stringify(response.data.data)
          );
          setNotifications(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const markNotificationAsRead = (index: any) => {
    setCount(Math.random());
    let all_notifications = notifications;
    if (!all_notifications[index].is_read) {
      all_notifications[index].is_read = true;
      setNotifications(all_notifications);
      localStorage.setItem(
        "loves_burger_notifications",
        JSON.stringify(all_notifications)
      );

      const url = BASE_URL + "/app/read-notification";
      if (mode == "demo") {
        console.log(mode);
      } else {
        axios
          .post(
            url,
            {
              id: all_notifications[index].id,
            },
            {
              headers: {
                Authorization: `Bearer ${cookies.get(
                  "access_token_loves_burger"
                )}`,
              },
            }
          )
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    }

    history.push("/single-notification/" + index);
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    const req_url = BASE_URL + "/app/notifications";
    debugger;
    if (mode == "demo") {
      instance.get("/app/notifications").then((response) => {
        console.log(response);
        localStorage.setItem(
          "loves_burger_notifications",
          JSON.stringify(response.data.data)
        );
        setNotifications(response.data.data);
        event.detail.complete();
      });
    } else {
      axios
        .get(req_url, {
          headers: {
            Authorization: `Bearer ${cookies.get("access_token_loves_burger")}`,
          },
        })
        .then((res) => {
          localStorage.setItem(
            "loves_burger_notifications",
            JSON.stringify(res.data.data)
          );
          event.detail.complete();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <IonPage>
      <IonHeader className="header-center">
        <IonToolbar color="primary">
          <IonButtons slot="start" className="ion-margin-start">
            <IonBackButton text="" icon={arrowBackOutline} />
          </IonButtons>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList className="side-avatar-list custom-list">
          {notifications.map((item: any, index: any) => {
            var d = new Date(item.created_at);
            var year = d.getFullYear();
            var month = d.getMonth();
            month = month + 1;
            var date = d.getDate();

            var full_date = date + "-" + month + "-" + year;
            return (
              <IonItem
                key={index}
                color={item.is_read ? "" : "light"}
                button={true}
                onClick={() => {
                  markNotificationAsRead(index);
                }}
              >
                <IonLabel>
                  <h2 className="">{item.notification.title}</h2>
                  <p>{item.notification.description}</p>
                </IonLabel>

                <IonText slot="end">{full_date}</IonText>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
